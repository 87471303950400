import YouTubeIcon from '@mui/icons-material/YouTube';
import {
  Box,
  Button,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { isValidYoutube } from '../../../../utils/usefulFunctions';
import { Specialization } from '../../../gw2components';
import Label from '../../../Label';
import Message from '../../../Message';
import ModalVideo from '../../../ModalVideo';
import Timer from './Timer';

const duration = require('dayjs/plugin/duration');

dayjs.extend(duration);

const professions = {
  Guardian: ['Dragonhunter', 'Firebrand', 'Willbender'],
  Revenant: ['Herald', 'Renegade', 'Vindicator'],
  Warrior: ['Berserker', 'Spellbreaker', 'Bladesworn'],
  Engineer: ['Scrapper', 'Holosmith', 'Mechanist'],
  Ranger: ['Druid', 'Soulbeast', 'Untamed'],
  Thief: ['Daredevil', 'Deadeye', 'Specter'],
  Elementalist: ['Tempest', 'Weaver', 'Catalyst'],
  Mesmer: ['Chronomancer', 'Mirage', 'Virtuoso'],
  Necromancer: ['Reaper', 'Scourge', 'Harbinger'],
};

const useStyles = makeStyles()((theme) => ({
  textfield: {
    maxWidth: 230,
    [theme.breakpoints.up('sm')]: {
      width: 400,
      maxWidth: 400,
    },
    marginRight: theme.spacing(2),
  },
  timerTextfield: {
    width: 80,
  },
  formControl: {
    minWidth: 200,
  },
  headline: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  descriptionField: {
    width: '100%',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function StepEncounter({
  onChangeProfession,
  onChangeVideos,
  onChange,
  onChangeTimer,
  timer,
  log,
  playerNames,
  videos,
  description,
  professions: inputProfessions,
}) {
  const { classes } = useStyles();
  const [state, setState] = React.useState({
    hasVideo: videos.map((video) => !!video),
  });

  const allProfessions = Object.keys(professions).flatMap((prof) => [
    prof,
    ...professions[prof],
  ]);

  return (
    <>
      {typeof log === 'string' && (
        <>
          <Typography className={classes.headline}>Log</Typography>
          <TextField
            required
            className={classes.textfield}
            label="Log"
            variant="outlined"
            value={log}
            name="log"
            onChange={onChange}
          />
        </>
      )}

      <Typography className={classes.headline}>Timer</Typography>

      <Timer onChange={onChangeTimer} millis={timer} />

      <Typography className={classes.headline}>Players</Typography>
      {playerNames.map((name, index) =>
        name ? (
          <Box m={2} key={`Player${index.toString()}`}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={2}>
                <Label> {name} </Label>
              </Grid>
              <Grid item>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id={`prof-select-label-${index}`}>
                    Profession
                  </InputLabel>
                  <Select
                    required
                    labelId={`prof-select-label-${index}`}
                    id={`prof-select-${index}`}
                    value={inputProfessions[index]}
                    onChange={onChangeProfession(index)}
                    label="Profession"
                    MenuProps={MenuProps}
                  >
                    {allProfessions.map((prof) => (
                      <MenuItem value={prof} key={prof}>
                        <Specialization name={prof} disableLink />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                {state.hasVideo[index] ? (
                  <Fade in={state.hasVideo[index]}>
                    <div>
                      <TextField
                        className={classes.textfield}
                        label="Video"
                        variant="outlined"
                        value={videos[index]}
                        name={`Video${index}`}
                        onChange={onChangeVideos(index)}
                      />
                      {isValidYoutube(videos[index]) && (
                        <ModalVideo src={videos[index]}>
                          {(handleOpen) => (
                            <IconButton onClick={handleOpen} size="large">
                              <YouTubeIcon />
                            </IconButton>
                          )}
                        </ModalVideo>
                      )}
                    </div>
                  </Fade>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    startIcon={<YouTubeIcon />}
                    onClick={(e) => {
                      const hasVideo = [...state.hasVideo];
                      hasVideo[index] = true;
                      setState({ ...state, hasVideo });
                    }}
                  >
                    Add video
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Message key={`Player${index.toString()}`}>
            Please add players in the first step!
          </Message>
        ),
      )}
      <Typography className={classes.headline}>
        Description (optional)
      </Typography>
      <TextField
        id="outlined-multiline-description"
        label="Description"
        name="description"
        multiline
        rows={10}
        value={description}
        className={classes.descriptionField}
        onChange={onChange}
        variant="outlined"
      />
    </>
  );
}

export default StepEncounter;

import { Spinner } from '@discretize/gw2-ui-new';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import axios from 'axios';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Message from '../../../Message';
import { ERROR, IDLE, SUCCESS, TRANSMITTING } from './status';
import StepEncounter from './StepEncounter';
import StepTeam from './StepTeam';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const isValidArray = (arr) => arr.every((elem) => !!elem);
const isValidForm = (obj) =>
  !!obj.guildName &&
  !!obj.guildTag &&
  !!obj.logo &&
  isValidArray(obj.playerNames) &&
  isValidArray(obj.t4.professions) &&
  !!obj.t4.timer &&
  parseInt(obj.t4.timer, 10) > 60000 &&
  !!obj.cm.log &&
  !!obj.cm.timer &&
  parseInt(obj.cm.timer, 10) > 10000 &&
  isValidArray(obj.cm.professions);

function VerticalLinearStepper() {
  const { classes } = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [status, setStatus] = React.useState(IDLE);
  const [state, setState] = React.useState(
    {
      guildName: '',
      guildTag: '',
      logo: '',
      playerNames: Array(5).fill(''),
      t4: {
        log: '',
        timer: '',
        professions: Array(5).fill(''),
        videos: Array(5).fill(''),
        description: '',
      },
      cm: {
        log: '',
        timer: '',
        professions: Array(5).fill(''),
        videos: Array(5).fill(''),
        description: '',
      },
    } /*
    {
      guildName: 'Discretize',
      guildTag: 'dT',
      logo: 'https://old.discretize.eu/_/img/discretize-512.png',
      playerNames: ['Jetrell', 'Inky', 'Zyp', 'mkchan', 'Princeps'],
      t4: {
        log: 'https://dps.report/epicarchdivinerlink',
        timer: '123123',
        professions: [
          'Necromancer',
          'Renegade',
          'Dragonhunter',
          'Soulbeast',
          'Firebrand',
        ],
        videos: ['https://youtu.be/TyNmd6b5z4w', '', '', '', ''],
        description: '',
      },
      cm: {
        log: 'https://dps.report/rrtd-20211022-184045_arriv',
        timer: '123123',
        professions: [
          'Necromancer',
          'Renegade',
          'Dragonhunter',
          'Soulbeast',
          'Firebrand',
        ],
        videos: [
          'https://www.youtube.com/watch?v=e_u4pxdW_yw',
          'https://youtu.be/TyNmd6b5z4w',
          'https://youtu.be/yj--KBwQSmA',
          '',
          'https://www.youtube.com/watch?v=1KDJjkkGw70',
        ],
        description: '',
      },
    },*/,
  );

  const changeNestedState = (target, object) => (index) => (e) => {
    const data = [...state[target][object]];
    data[index] = e.target.value.trim();

    setState({
      ...state,
      [target]: {
        ...state[target],
        [object]: data,
      },
    });
  };

  const steps = ['Team info', 'T4 data', 'CM data'];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StepTeam
            onChange={(e) =>
              setState({ ...state, [e.target.name]: e.target.value })
            }
            onChangePlayer={(index) => (e) => {
              const playerNames = [...state.playerNames];
              playerNames[index] = e.target.value;

              setState({
                ...state,
                playerNames,
              });
            }}
            state={state}
          />
        );

      case 1:
        return (
          <StepEncounter
            professions={state.t4.professions}
            playerNames={state.playerNames}
            videos={state.t4.videos}
            log={state.t4.log}
            description={state.t4.description}
            onChangeTimer={(newTimer) => {
              setState({ ...state, t4: { ...state.t4, timer: `${newTimer}` } });
            }}
            timer={state.t4.timer}
            onChange={(e) => {
              setState({
                ...state,
                t4: { ...state.t4, [e.target.name]: e.target.value },
              });
            }}
            onChangeProfession={changeNestedState('t4', 'professions')}
            onChangeVideos={changeNestedState('t4', 'videos')}
          />
        );
      case 2:
        return (
          <StepEncounter
            professions={state.cm.professions}
            playerNames={state.playerNames}
            videos={state.cm.videos}
            log={state.cm.log}
            description={state.cm.description}
            onChangeTimer={(newTimer) =>
              setState({ ...state, cm: { ...state.cm, timer: `${newTimer}` } })
            }
            timer={state.cm.timer}
            onChange={(e) => {
              setState({
                ...state,
                cm: { ...state.cm, [e.target.name]: e.target.value },
              });
            }}
            onChangeProfession={changeNestedState('cm', 'professions')}
            onChangeVideos={changeNestedState('cm', 'videos')}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  const finalStep = () => {
    let Content;
    switch (status) {
      case SUCCESS:
        Content = (
          <Typography>
            We received your submission! Somebody from the team will reach out
            to you shortly about the validity of your submission.
          </Typography>
        );
        break;
      case ERROR:
        Content = (
          <Message>Something went wrong - please try again later!</Message>
        );
        break;
      case TRANSMITTING:
        Content = (
          <Typography>
            <Spinner /> Transmitting...
          </Typography>
        );
        break;
      default:
        Content = (
          <Message>Something went wrong - please try again later!</Message>
        );
    }
    return (
      <Paper square elevation={0} className={classes.resetContainer}>
        {Content}
      </Paper>
    );
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setStatus(TRANSMITTING);
    if (activeStep === steps.length - 1) {
      // submit form
      axios
        .post('https://bounties-serverless-submit.princeps.workers.dev', {
          ...state,
        })
        .then((res) => {
          const json = res.data;
          if (json.status === 'SUCCESS') {
            setStatus(SUCCESS);
          } else {
            setStatus(ERROR);
          }
        })
        .catch((err) => {
          setStatus(ERROR);
        });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Box p={1}>{getStepContent(index)}</Box>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={
                      activeStep === steps.length - 1 && !isValidForm(state)
                    }
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && finalStep()}
    </div>
  );
}

function SubmissionForm() {
  return <VerticalLinearStepper />;
}

export default SubmissionForm;

import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Label from '../../../Label';

const useStyles = makeStyles()((theme) => ({
  textfield: {
    maxWidth: 230,
    [theme.breakpoints.up('sm')]: {
      width: 350,
      maxWidth: 350,
    },
  },
  logoPreview: { width: 64, height: 64, marginLeft: theme.spacing(1) },
}));

const teamFields = [
  { name: 'guildName', label: 'Guild Name', required: true },
  { name: 'guildTag', label: 'Guild Tag', required: true },
  { name: 'logo', label: 'Link to logo', required: true },
];

const StepTeam = ({ onChange, state, onChangePlayer }) => {
  const { classes } = useStyles();

  return (
    <>
      <Typography>Team</Typography>

      {teamFields.map(({ label, name, required }) => (
        <Box m={2} key={name}>
          <TextField
            required={required}
            className={classes.textfield}
            label={label}
            variant="outlined"
            value={state[name]}
            name={name}
            onChange={onChange}
          />
          {name === 'logo' && state[name] && (
            <img
              className={classes.logoPreview}
              src={state[name]}
              alt="guild logo"
            />
          )}
        </Box>
      ))}
      <Typography>
        Players <Label>either in game name or discord tag</Label>
      </Typography>
      {[...Array(5).keys()].map((index) => (
        <Box m={2} key={`Player${index}`}>
          <TextField
            required
            className={classes.textfield}
            label={`Player name ${index + 1}`}
            variant="outlined"
            value={state.playerNames[index]}
            name={`player${index}`}
            onChange={onChangePlayer(index)}
          />
        </Box>
      ))}
    </>
  );
};

export default StepTeam;

export const getYoutubeId = (str) => {
  return str
    .replace('https://www.youtube.com/watch?v=', '')
    .replace('https://youtu.be/', '')
    .trim();
};
export const isValidYoutube = (str) => {
  if (!str) return false;
  return (
    str.includes('https://www.youtube.com/watch?v=') ||
    str.includes('https://youtu.be/')
  );
};

import React from 'react';
import { Paper, SvgIconTypeMap, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import WarningIcon from '@mui/icons-material/Warning';
import classNames from 'classnames';
import { iconSizes } from '@discretize/react-discretize-components';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const useStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    gap: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  icon: {
    fontSize: iconSizes.large,
    marginRight: theme.spacing(2),
  },
  text: {
    fontSize: '1rem',
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
}));

interface MessageProps {
  className?: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  children: React.ReactNode;
}

function Message({
  className,
  icon: Icon = WarningIcon,
  children,
}: MessageProps) {
  const { classes } = useStyles();
  return (
    <Paper className={classNames(classes.root, className)}>
      <Icon className={classes.icon} />
      <Typography component="div" className={classes.text}>
        {children}
      </Typography>
    </Paper>
  );
}
export default Message;

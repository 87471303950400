import React from 'react';
import Header from '../../../components/pages/bounties/Header';
import SubmissionForm from '../../../components/pages/bounties/submission/SubmissionForm';
import Layout from '../../../components/Layout';
import SeoHeader from '../../../components/SeoHeader';

const layout = {
  SeoProps: {
    title: 'Bounties Submissions',
    description:
      'Play together with your friends and compete for awesome prizes in the bounties event!',
  },
  ContainerProps: {
    paper: true,
  },
};

function BuildsPage({ location }) {
  return (
    <Layout ContainerProps={layout.ContainerProps} location={location}>
      <SeoHeader path={location.pathname} {...layout.SeoProps} />

      <Header page="/bounties/submission" title="Bounties Submissions" />

      <SubmissionForm />
    </Layout>
  );
}

export default BuildsPage;

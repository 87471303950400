import { TextField } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  timerTextfield: {
    width: 80,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}))

const Timer = ({ millis, onChange }) => {
  const { classes } = useStyles()
  const [state, setState] = React.useState({
    min: 0,
    sec: 0,
    ms: 0,
  })

  React.useEffect(() => {
    const min = Math.floor(millis / 1000 / 60)
    const sec = Math.floor((millis - min * 60 * 1000) / 1000)
    const ms = Math.floor(millis - sec * 1000 - min * 1000 * 60)
    console.log(min)
    console.log(sec)
    console.log(ms)
    setState({ min, sec, ms })
  }, [])

  const millisFromState = ({ min, sec, ms }) => {
    return (min * 60 + sec) * 1000 + ms
  }

  const changeHandler = (value, type) => {
    const newValue = parseInt(value, 10) || 0
    const newState = { ...state, [type]: newValue }
    setState(newState)
    onChange(millisFromState(newState))
  }

  return (
    <>
      <TextField
        className={classes.timerTextfield}
        label="Minutes"
        variant="outlined"
        value={state.min}
        onChange={(e) => changeHandler(e.target.value, 'min')}
        name="Minutes"
      />
      <TextField
        className={classes.timerTextfield}
        label="Seconds"
        variant="outlined"
        value={state.sec}
        onChange={(e) => changeHandler(e.target.value, 'sec')}
        name="Seconds"
      />
      <TextField
        className={classes.timerTextfield}
        label="Milliseconds"
        variant="outlined"
        value={state.ms}
        onChange={(e) => changeHandler(e.target.value, 'ms')}
        name="Milliseconds"
      />
    </>
  )
}

export default Timer

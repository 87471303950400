import { Box, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import useWindowDimensions from '../hooks/useWindowDimension';
import { getYoutubeId } from '../utils/usefulFunctions';

const useStyles = makeStyles()((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
  },
  video: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  closeIcon: {
    fontSize: 50,
  },
}));

interface ModalVideoProps {
  src: string;
  children: (handleOpen: () => void) => React.ReactNode;
  title: string;
  timestamp?: number;
}

function ModalVideo({ src, children, title, timestamp = 0 }: ModalVideoProps) {
  const { classes } = useStyles();
  const { width, height } = useWindowDimensions();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let frameWidth = 1920;
  let frameHeight = 1080;
  if (height < (width * 9) / 16) {
    // strange display ratio where the height is significantly smaller than on a normal screen
    frameWidth = (height * 16) / 9 - 50;
    frameHeight = height - 100;
  } else {
    // "normal" viewport
    frameWidth = width - 50;
    frameHeight = (width * 9) / 16 - (width > 1000 ? 100 : 0);
  }
  // dont scale it bigger than 1920x1080
  if (width > 1920 && height > 1080) {
    frameWidth = 1920;
    frameHeight = 1080;
  }

  return (
    <>
      {children(handleOpen)}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box display="flex" justifyContent="space-between">
              {title && (
                <Box alignSelf="center">
                  <Typography>{title}</Typography>
                </Box>
              )}

              <Box>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={() => setOpen(false)}
                />
              </Box>
            </Box>

            <div className={classes.video}>
              <iframe
                width={frameWidth}
                height={frameHeight}
                src={`https://www.youtube.com/embed/${getYoutubeId(
                  src,
                )}?autoplay=1${
                  timestamp ? `&start=${timestamp}` : ''
                }&vq=hd1080`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
export default ModalVideo;
